import { CheckoutPaymentQuery } from "@/checkout/payment/__generated__/CheckoutPaymentQuery.graphql"
import { CheckoutStep } from "@/checkout/utils/CheckoutUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import StripeUtils from "@/stripe/util/StripeUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { graphql } from "relay-runtime"

type Props = {
  validCheckoutId: string
  onPayment: (params: { validCheckoutId: GlobalID; nextStep: CheckoutStep }) => void
}

function CheckoutPayment({ validCheckoutId, onPayment }: Props) {
  const classes = useStyles()
  const stripePromise = StripeUtils.useLoadStripe()

  return (
    <div className={classes.container}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ fetchClientSecret, onComplete }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )

  async function fetchClientSecret(): Promise<string> {
    const res = await Relay.runQuery<CheckoutPaymentQuery>(
      graphql`
        query CheckoutPaymentQuery($id: ID!) {
          validCheckout: node(id: $id) {
            ... on ValidCheckout {
              stripeCheckoutClientSecret
            }
          }
        }
      `,
      { id: validCheckoutId },
      { fetchPolicy: "network-only" }
    )

    if (!res?.validCheckout?.stripeCheckoutClientSecret) return ""
    return res.validCheckout.stripeCheckoutClientSecret
  }

  function onComplete() {
    onPayment({ validCheckoutId, nextStep: "process" })
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      "& > div": {
        height: "100vh",

        "& > iframe": {
          height: "100% !important",
        },
      },
    },
  },
}))

export default CheckoutPayment
