/**
 * @generated SignedSource<<6842a0e2bd96f129a7d98395a90e4cd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type CheckoutExperienceListItemQuery$variables = {
  id: string;
};
export type CheckoutExperienceListItemQuery$data = {
  readonly experience: {
    readonly id?: string;
    readonly name?: string;
    readonly cover?: string;
    readonly startsAt?: string | null;
    readonly endsAt?: string | null;
    readonly includedInBenefits?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly membershipPlanId: string;
          readonly pricing: {
            readonly id: string;
            readonly amountCents: number;
            readonly frequency: PricingFrequency | null;
            readonly kind: PricingKind;
          };
        };
      }>;
    } | null;
  } | null;
};
export type CheckoutExperienceListItemQuery = {
  variables: CheckoutExperienceListItemQuery$variables;
  response: CheckoutExperienceListItemQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "hideDrafts",
      "value": true
    }
  ],
  "concreteType": "MembershipBenefitNodeConnection",
  "kind": "LinkedField",
  "name": "includedInBenefits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipBenefitNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipBenefit",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "membershipPlanId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Pricing",
              "kind": "LinkedField",
              "name": "pricing",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amountCents",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "frequency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "includedInBenefits(hideDrafts:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutExperienceListItemQuery",
    "selections": [
      {
        "alias": "experience",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutExperienceListItemQuery",
    "selections": [
      {
        "alias": "experience",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2516b52c27bd17566ecd3a61ea66e7d5",
    "id": null,
    "metadata": {},
    "name": "CheckoutExperienceListItemQuery",
    "operationKind": "query",
    "text": "query CheckoutExperienceListItemQuery(\n  $id: ID!\n) {\n  experience: node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      name\n      cover\n      startsAt\n      endsAt\n      includedInBenefits(hideDrafts: true) {\n        edges {\n          node {\n            id\n            membershipPlanId\n            pricing {\n              id\n              amountCents\n              frequency\n              kind\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "50c5bc4f091890356e9136299591158e";

export default node;
