import CheckoutComplete from "@/checkout/complete/CheckoutComplete"
import CheckoutPageHeader from "@/checkout/components/CheckoutPageHeader"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { useParams } from "react-router-dom"

type CheckoutCompletePageParams = {
  validCheckoutId: GlobalID
}

function CheckoutCompletePage() {
  const { validCheckoutId } = useParams<CheckoutCompletePageParams>()
  const classes = useStyles()

  return (
    <DiscoPage
      headerContent={<CheckoutPageHeader step={"complete"} hideBack />}
      classes={{ container: classes.root }}
    >
      <CheckoutComplete validCheckoutId={validCheckoutId} />
    </DiscoPage>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default CheckoutCompletePage
