/**
 * @generated SignedSource<<ba03a1ba50548ca7212cfaf2b2ee3bd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelThreadContentFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelMessageFragment">;
  readonly " $fragmentType": "ChatChannelThreadContentFragment";
};
export type ChatChannelThreadContentFragment$key = {
  readonly " $data"?: ChatChannelThreadContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelThreadContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelThreadContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelMessageFragment"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};

(node as any).hash = "cad35931d5ec628a8c4190d26a999b91";

export default node;
