import Badge from "@/admin/experiences/badges/Badge"
import { ChatChannelMainThreadFragment$key } from "@/chat/channel/page/content/__generated__/ChatChannelMainThreadFragment.graphql"
import { ChatChannelMainThreadMutation } from "@/chat/channel/page/content/__generated__/ChatChannelMainThreadMutation.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import HeaderContent from "@/main/page/header/HeaderContent"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannel from "@components/chat/channel/ChatChannel"
import DirectMessageHeader from "@components/chat/channel/detail/header/DirectMessageHeader"
import ChatChannelMessage from "@components/chat/channel/detail/message/ChatChannelMessage"
import ChatChannelMessageInput from "@components/chat/channel/message-input/ChatChannelMessageInput"
import ThreadButton from "@components/chat/channel/ThreadButton"
import useUnreadThreadCount from "@components/chat/channel/util/useUnreadThreadCount"
import { DiscoLink, DiscoSection, DiscoText } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useCallback, useEffect } from "react"
import { useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"
import { MessageActionsArray, Thread } from "stream-chat-react"

interface ChatChannelMainThreadProps extends TestIDProps {
  threadKey: ChatChannelMainThreadFragment$key
  fullPage?: boolean
}

const MESSAGE_ACTIONS: MessageActionsArray<string> = [
  "mute",
  "edit",
  "quote",
  "reply",
  "delete",
  "react",
]

function ChatChannelMainThread({
  threadKey,
  testid = "ChatChannelMainThread",
  fullPage = false,
}: ChatChannelMainThreadProps) {
  const classes = useStyles()
  const theme = useTheme()
  const thread = useFragment<ChatChannelMainThreadFragment$key>(
    graphql`
      fragment ChatChannelMainThreadFragment on Thread {
        id
        messageId
        chatChannel {
          id
          externalChannelId
          kind
          visibility
          product {
            name
            slug
          }
          app {
            badge {
              ...BadgeFragment
            }
            customAppTitle
          }
          ...DirectMessageHeaderFragment
          ...ChatChannelMessageFragment
          ...ChatChannelFragment
        }
        viewerThreadMember {
          readAt
        }
      }
    `,
    threadKey
  )

  const ThreadInput = useCallback(
    () => <ChatChannelMessageInput chatChannelId={thread.chatChannel.id} forThread />,
    [thread.chatChannel.id]
  )

  const { chatChannel, messageId, viewerThreadMember } = thread

  // Mark the thread as read when page is opened
  const { refetchUnreadThreadCount } = useUnreadThreadCount()
  const markAsRead = Relay.useAsyncMutation<ChatChannelMainThreadMutation>(
    graphql`
      mutation ChatChannelMainThreadMutation($input: MarkThreadAsReadInput!) {
        response: markThreadAsRead(input: $input) {
          threadMember {
            id
            readAt
          }
        }
      }
    `
  )
  useEffect(() => {
    if (!fullPage || viewerThreadMember?.readAt) return
    markAsRead({ input: { threadId: thread.id } }).then(refetchUnreadThreadCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread.id])

  const threadComponent = (
    <Thread
      Input={ThreadInput}
      messageActions={MESSAGE_ACTIONS}
      additionalMessageListProps={{
        messageActions: MESSAGE_ACTIONS,
        noGroupByUser: true,
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false} // Disable autofocus when the thread window opens
      Message={(props) => (
        <ChatChannelMessage {...props} chatChannelKey={thread.chatChannel} />
      )}
    />
  )

  return (
    <ChatChannel
      key={chatChannel.externalChannelId}
      mainThread
      testid={"ChatChannelMainThread.channel"}
      chatChannelKey={thread.chatChannel}
    >
      <ThreadButton messageId={messageId} />
      {renderHeader()}
      {fullPage ? (
        threadComponent
      ) : (
        <DiscoSection padding={1}>{threadComponent}</DiscoSection>
      )}
    </ChatChannel>
  )

  function renderHeader() {
    const header =
      chatChannel.kind === "direct_message" ? (
        /** DM */
        <DiscoLink
          data-testid={`${testid}.channel-link`}
          to={{
            // Link to main channel with thread open
            pathname: generatePath(ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL, {
              channelId: chatChannel.id,
            }),
            search: `streamMsgId=${messageId}&openThread=true`,
          }}
        >
          <DirectMessageHeader
            channelKey={chatChannel}
            textColor={"text.primary"}
            textVariant={"body-sm"}
            avatarSize={24}
          />
        </DiscoLink>
      ) : (
        /** Communtiy or Product channel */
        <>
          <div className={classes.channelHeaderText}>
            {!fullPage && chatChannel.app?.badge && (
              <Badge
                badgeKey={chatChannel.app.badge}
                className={classes.channelBadge}
                size={24}
              />
            )}
            <DiscoLink
              data-testid={`${testid}.channel-link`}
              to={{
                // Link to main channel with thread open
                pathname: chatChannel.product
                  ? generatePath(ROUTE_NAMES.PRODUCT.CHAT.CHANNEL, {
                      channelId: chatChannel.id,
                      productSlug: chatChannel.product.slug,
                    })
                  : generatePath(ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL, {
                      channelId: chatChannel.id,
                    }),
                search: `streamMsgId=${messageId}&openThread=true`,
              }}
            >
              <DiscoText noWrap variant={"body-md-500"} color={"text.primary"}>
                {chatChannel.app?.customAppTitle}
              </DiscoText>
            </DiscoLink>
          </div>

          {chatChannel.product && (
            <DiscoText noWrap variant={"body-sm"} color={"text.secondary"}>
              {"in "}
              <DiscoLink
                to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                  productSlug: chatChannel.product.slug,
                })}
                className={classes.productLink}
              >
                {chatChannel.product.name}
              </DiscoLink>
            </DiscoText>
          )}
        </>
      )
    return fullPage ? (
      <HeaderContent
        leftIcon={
          chatChannel.app?.badge && (
            <Badge
              badgeKey={chatChannel.app.badge}
              className={classes.fullPageBadge}
              size={40}
            />
          )
        }
        title={<div className={classes.headerTitle}>{header}</div>}
        rightOptions={
          chatChannel.visibility === "private" && (
            <DiscoTag
              testid={`${testid}.private-channel`}
              name={"Private"}
              backgroundColor={theme.palette.groovy.neutral[100]}
            />
          )
        }
      />
    ) : (
      <div className={classes.channelHeader}>{header}</div>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  headerTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  channelHeader: {
    marginBottom: theme.spacing(1),
  },
  channelHeaderText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  channelBadge: {
    backgroundColor: "transparent",
  },
  fullPageBadge: {
    backgroundColor: theme.palette.background.default,
  },
  productLink: {
    fontSize: "inherit",
  },
}))

export default ChatChannelMainThread
