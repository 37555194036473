import CheckoutPageHeader from "@/checkout/components/CheckoutPageHeader"
import CheckoutPayment from "@/checkout/payment/CheckoutPayment"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { generatePath, useHistory, useParams } from "react-router-dom"

type CheckoutPaymentPageParams = {
  validCheckoutId: string
}

function CheckoutPaymentPage() {
  const { validCheckoutId } = useParams<CheckoutPaymentPageParams>()
  const history = useHistory()
  const classes = useStyles()

  return (
    <DiscoPage
      headerContent={<CheckoutPageHeader step={"payment"} hideBorder />}
      classes={{ container: classes.root, content: classes.content }}
    >
      <CheckoutPayment validCheckoutId={validCheckoutId} onPayment={onPayment} />
    </DiscoPage>
  )

  function onPayment(params: { validCheckoutId: GlobalID }) {
    history.replace(
      generatePath(ROUTE_NAMES.CHECKOUT.PROCESS, {
        validCheckoutId: params.validCheckoutId,
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    maxWidth: "none",
  },
}))

export default CheckoutPaymentPage
