/**
 * @generated SignedSource<<ccb05e0772d8a08282450e75d51ae520>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatChannelKind = "default" | "custom" | "direct_message" | "chat_bot" | "%future added value";
export type ChatChannelVisibility = "public" | "private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatChannelMainThreadFragment$data = {
  readonly id: string;
  readonly messageId: string;
  readonly chatChannel: {
    readonly id: string;
    readonly externalChannelId: string;
    readonly kind: ChatChannelKind;
    readonly visibility: ChatChannelVisibility;
    readonly product: {
      readonly name: string;
      readonly slug: string;
    } | null;
    readonly app: {
      readonly badge: {
        readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
      };
      readonly customAppTitle: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"DirectMessageHeaderFragment" | "ChatChannelMessageFragment" | "ChatChannelFragment">;
  };
  readonly viewerThreadMember: {
    readonly readAt: string | null;
  } | null;
  readonly " $fragmentType": "ChatChannelMainThreadFragment";
};
export type ChatChannelMainThreadFragment$key = {
  readonly " $data"?: ChatChannelMainThreadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelMainThreadFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelMainThreadFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "chatChannel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalChannelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductApp",
          "kind": "LinkedField",
          "name": "app",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Badge",
              "kind": "LinkedField",
              "name": "badge",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BadgeFragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customAppTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DirectMessageHeaderFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChatChannelMessageFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChatChannelFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThreadMember",
      "kind": "LinkedField",
      "name": "viewerThreadMember",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Thread",
  "abstractKey": null
};
})();

(node as any).hash = "f2cb0fc8af526f938df24bbc90a146d7";

export default node;
