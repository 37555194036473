/**
 * @generated SignedSource<<a56cfdcabeda9ae9e924e2c9266faf3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StartCheckoutInput = {
  validCheckoutId: string;
  versionKey: string;
};
export type CheckoutSummary_StartCheckoutMutation$variables = {
  input: StartCheckoutInput;
};
export type CheckoutSummary_StartCheckoutMutation$data = {
  readonly response: {
    readonly node: {
      readonly stripeCheckoutSessionId: string | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type CheckoutSummary_StartCheckoutMutation = {
  variables: CheckoutSummary_StartCheckoutMutation$variables;
  response: CheckoutSummary_StartCheckoutMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeCheckoutSessionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutSummary_StartCheckoutMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "StartCheckoutResponse",
        "kind": "LinkedField",
        "name": "startCheckout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidCheckout",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutSummary_StartCheckoutMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "StartCheckoutResponse",
        "kind": "LinkedField",
        "name": "startCheckout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidCheckout",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0641448fc0d24eb105b52520a00f9d1d",
    "id": null,
    "metadata": {},
    "name": "CheckoutSummary_StartCheckoutMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutSummary_StartCheckoutMutation(\n  $input: StartCheckoutInput!\n) {\n  response: startCheckout(input: $input) {\n    node {\n      stripeCheckoutSessionId\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81aeb6d8d23bb835c027fbf8464cb6ab";

export default node;
