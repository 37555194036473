/**
 * @generated SignedSource<<cadd71459a136d3dccc19273acb36a14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckoutSummaryPlanSelectionQuery$variables = {
  id: string;
  productIds: ReadonlyArray<string>;
  ignoreActivePlan: boolean;
};
export type CheckoutSummaryPlanSelectionQuery$data = {
  readonly organization: {
    readonly plansInclusiveOfProducts?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly pricing: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"CheckoutEntityListItem_PricingFragment">;
          } | null;
          readonly membershipBenefits: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly productId: string | null;
                readonly pricing: {
                  readonly id: string;
                };
              };
            }>;
          } | null;
        };
      }>;
    };
  } | null;
};
export type CheckoutSummaryPlanSelectionQuery = {
  variables: CheckoutSummaryPlanSelectionQuery$variables;
  response: CheckoutSummaryPlanSelectionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ignoreActivePlan"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productIds"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "kind": "Variable",
  "name": "productIds",
  "variableName": "productIds"
},
v5 = [
  {
    "kind": "Variable",
    "name": "ignoreActivePlan",
    "variableName": "ignoreActivePlan"
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "hideDrafts",
  "value": true
},
v10 = {
  "alias": null,
  "args": [
    (v9/*: any*/),
    (v4/*: any*/)
  ],
  "concreteType": "MembershipBenefitNodeConnection",
  "kind": "LinkedField",
  "name": "membershipBenefits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipBenefitNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipBenefit",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Pricing",
              "kind": "LinkedField",
              "name": "pricing",
              "plural": false,
              "selections": [
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutSummaryPlanSelectionQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "plansInclusiveOfProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Pricing",
                            "kind": "LinkedField",
                            "name": "pricing",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CheckoutEntityListItem_PricingFragment"
                              }
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CheckoutSummaryPlanSelectionQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "plansInclusiveOfProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Pricing",
                            "kind": "LinkedField",
                            "name": "pricing",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountCents",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "frequency",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "membershipPlan",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      (v9/*: any*/),
                                      {
                                        "kind": "Literal",
                                        "name": "hideNonPublic",
                                        "value": true
                                      }
                                    ],
                                    "concreteType": "MembershipBenefitNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "membershipBenefits",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "totalCount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "membershipBenefits(hideDrafts:true,hideNonPublic:true)"
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MembershipBenefit",
                                "kind": "LinkedField",
                                "name": "membershipBenefit",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "membershipPlan",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v11/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "startsAt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "endsAt",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea2ea6e5a927f7c302bafda1a99fb298",
    "id": null,
    "metadata": {},
    "name": "CheckoutSummaryPlanSelectionQuery",
    "operationKind": "query",
    "text": "query CheckoutSummaryPlanSelectionQuery(\n  $id: ID!\n  $productIds: [ID!]!\n  $ignoreActivePlan: Boolean!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      plansInclusiveOfProducts(productIds: $productIds, ignoreActivePlan: $ignoreActivePlan) {\n        edges {\n          node {\n            id\n            name\n            slug\n            pricing {\n              id\n              ...CheckoutEntityListItem_PricingFragment\n            }\n            membershipBenefits(productIds: $productIds, hideDrafts: true) {\n              edges {\n                node {\n                  id\n                  productId\n                  pricing {\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment CheckoutEntityListItem_PricingFragment on Pricing {\n  id\n  amountCents\n  frequency\n  kind\n  membershipPlan {\n    id\n    name\n    cover\n    membershipBenefits(hideNonPublic: true, hideDrafts: true) {\n      totalCount\n    }\n  }\n  membershipBenefit {\n    id\n    membershipPlan {\n      name\n      id\n    }\n    product {\n      id\n      name\n      cover\n      startsAt\n      endsAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5721244ac997c86455b57f37ca6b965f";

export default node;
