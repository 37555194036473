import CheckoutPageHeader from "@/checkout/components/CheckoutPageHeader"
import CheckoutProcess from "@/checkout/process/CheckoutProcess"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { generatePath, useHistory, useParams } from "react-router-dom"

type CheckoutProcessPageParams = {
  validCheckoutId: GlobalID
}

function CheckoutProcessPage() {
  const { validCheckoutId } = useParams<CheckoutProcessPageParams>()
  const history = useHistory()
  const classes = useStyles()

  return (
    <DiscoPage
      headerContent={<CheckoutPageHeader step={"process"} hideBack />}
      classes={{ container: classes.root }}
    >
      <CheckoutProcess validCheckoutId={validCheckoutId} onProcessed={onProcessed} />
    </DiscoPage>
  )

  function onProcessed(params: { validCheckoutId: GlobalID }) {
    history.replace(
      generatePath(ROUTE_NAMES.CHECKOUT.COMPLETE, {
        validCheckoutId: params.validCheckoutId,
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default CheckoutProcessPage
