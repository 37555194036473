import { OrganizationCheckoutVersion } from "@/admin/appearance/__generated__/AdminAppearancePageMutation.graphql"
import { CheckoutUtils_GetBenefitOnPlanQuery } from "@/checkout/utils/__generated__/CheckoutUtils_GetBenefitOnPlanQuery.graphql"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { PricingInput } from "@/pricing/pricingUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type Cart = {
  items: CartItem[]
  // Products that require plan selection
  orphanedProductIds?: string[] | null
  // Force plan selection in checkout summary
  forcePlanSelection?: boolean | null
}

export type CartItem = {
  pricingId: string
}

export type CheckoutStep = "summary" | "payment" | "process" | "complete"
export const CheckoutStepLabels: Record<CheckoutStep, string> = {
  summary: "Summary",
  payment: "Payment",
  process: "Processing",
  complete: "Complete",
}

export type CheckoutQueryParams = {
  returnPath?: string
}

export const CHECKOUT_PRODUCT_TYPES: Record<OrganizationCheckoutVersion, ProductType[]> =
  {
    stripe: ["course", "membership_plan", "community_event", "pathway"],
    stripe_acacia: ["course", "membership_plan"],
    "%future added value": [],
  }

export namespace CheckoutUtils {
  export function encodeCart(cart: Cart): string {
    return btoa(JSON.stringify(cart))
  }

  export function decodeCart(cart: string): Cart {
    return JSON.parse(atob(cart))
  }

  export function sumTotal(pricings: Pick<PricingInput, "amountCents">[]): number {
    const totalCents = pricings.reduce((total, pricing) => total + pricing.amountCents, 0)
    return totalCents / 100
  }
}

/**
 * Determine if a product type is supported by the checkout version.
 */
export function isSupportedCheckoutProduct(args: {
  checkoutVersion?: OrganizationCheckoutVersion | null
  productType?: ProductType | null
}): boolean {
  const { checkoutVersion, productType } = args
  if (!checkoutVersion || !productType) return false
  return CHECKOUT_PRODUCT_TYPES[checkoutVersion].includes(productType)
}

/**
 * Returns a callback function to start a checkout process for a pricing,
 * this will fetch the membership benefit for a product using either the user's
 * active membership plan or a provided plan ID.
 */
export function useStartEntityCheckout(
  product: { id: GlobalID; type: ProductType } | null
) {
  const activeOrganization = useActiveOrganization()
  const activePlan = activeOrganization?.viewerActiveMembershipPlan
  const registrationDrawer = useGlobalDrawer("registration")
  const profileDrawer = useGlobalDrawer("profileSettings")
  const history = useHistory()

  const experienceId = product?.type === "course" ? product.id : ""
  const planId = product?.type === "membership_plan" ? product.id : activePlan?.id ?? ""

  const { plan } = Relay.useSkippableLazyLoadQuery<CheckoutUtils_GetBenefitOnPlanQuery>(
    graphql`
      query CheckoutUtils_GetBenefitOnPlanQuery(
        $planId: ID!
        $experienceId: ID!
        $fetchBenefit: Boolean!
      ) {
        plan: node(id: $planId) {
          ... on Product {
            id
            membershipBenefit(productId: $experienceId) @include(if: $fetchBenefit) {
              id
              pricing {
                id
              }
            }
            pricing {
              id
            }
          }
        }
      }
    `,
    { planId, experienceId, fetchBenefit: Boolean(experienceId) },
    {
      skip:
        activeOrganization?.checkoutVersion !== "stripe_acacia" ||
        !isSupportedCheckoutProduct({
          checkoutVersion: activeOrganization?.checkoutVersion,
          productType: product?.type,
        }),
    }
  )

  const startEntityCheckout = (
    opts: { forcePlanSelection?: boolean; destination?: "drawer" | "page" } = {}
  ) => {
    const pricing = plan?.membershipBenefit?.pricing ?? plan?.pricing
    const cart: Cart = { items: [], orphanedProductIds: [] }

    // If the entity exists on the users active plan, add it to the cart
    if (pricing) {
      cart.items.push({
        pricingId: Relay.rawId(pricing.id),
      })
    }

    // Otherwise, add the product to the orphaned product list,
    // which will trigger the plan selection in the checkout summary
    else {
      cart.orphanedProductIds?.push(Relay.rawId(experienceId))
    }

    if (opts?.forcePlanSelection) {
      cart.forcePlanSelection = true
    }

    const encodedCart = CheckoutUtils.encodeCart(cart)

    // If the registration drawer is open, swap to the checkout drawer
    if (registrationDrawer.isOpen) {
      registrationDrawer.swap<"checkout">({
        cart: encodedCart,
      })
    }

    // If the profile drawer is open, swap to the checkout drawer
    else if (profileDrawer.isOpen) {
      profileDrawer.swap<"checkout">({
        cart: encodedCart,
      })
    }

    // Otherwise, navigate to the checkout summary page
    else {
      history.push(
        generatePath(ROUTE_NAMES.CHECKOUT.SUMMARY, {
          cart: encodedCart,
        })
      )
    }
  }

  return { startEntityCheckout }
}
