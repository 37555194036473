/**
 * @generated SignedSource<<7b9d671e5782124df23fd2b6b867514a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CheckoutUtils_GetBenefitOnPlanQuery$variables = {
  planId: string;
  experienceId: string;
  fetchBenefit: boolean;
};
export type CheckoutUtils_GetBenefitOnPlanQuery$data = {
  readonly plan: {
    readonly id?: string;
    readonly membershipBenefit?: {
      readonly id: string;
      readonly pricing: {
        readonly id: string;
      };
    };
    readonly pricing?: {
      readonly id: string;
    } | null;
  } | null;
};
export type CheckoutUtils_GetBenefitOnPlanQuery = {
  variables: CheckoutUtils_GetBenefitOnPlanQuery$variables;
  response: CheckoutUtils_GetBenefitOnPlanQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "experienceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchBenefit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "planId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Pricing",
  "kind": "LinkedField",
  "name": "pricing",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "condition": "fetchBenefit",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "productId",
          "variableName": "experienceId"
        }
      ],
      "concreteType": "MembershipBenefit",
      "kind": "LinkedField",
      "name": "membershipBenefit",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutUtils_GetBenefitOnPlanQuery",
    "selections": [
      {
        "alias": "plan",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CheckoutUtils_GetBenefitOnPlanQuery",
    "selections": [
      {
        "alias": "plan",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edfa17248f44d19822a89f9487afc52e",
    "id": null,
    "metadata": {},
    "name": "CheckoutUtils_GetBenefitOnPlanQuery",
    "operationKind": "query",
    "text": "query CheckoutUtils_GetBenefitOnPlanQuery(\n  $planId: ID!\n  $experienceId: ID!\n  $fetchBenefit: Boolean!\n) {\n  plan: node(id: $planId) {\n    __typename\n    ... on Product {\n      id\n      membershipBenefit(productId: $experienceId) @include(if: $fetchBenefit) {\n        id\n        pricing {\n          id\n        }\n      }\n      pricing {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "43036ed20e4abacb5ec2cfc1fc444d52";

export default node;
