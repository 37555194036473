import CheckoutPageHeader from "@/checkout/components/CheckoutPageHeader"
import CheckoutSummary from "@/checkout/summary/CheckoutSummary"
import { CheckoutStep, CheckoutUtils } from "@/checkout/utils/CheckoutUtils"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { generatePath, useHistory, useParams } from "react-router-dom"

export type CheckoutSummaryParams = {
  // This is a base64 encoded cart object
  cart: string
}

function CheckoutSummaryPage() {
  const { cart: encodedCart } = useParams<CheckoutSummaryParams>()
  const cart = CheckoutUtils.decodeCart(encodedCart)
  const history = useHistory()
  const classes = useStyles()

  return (
    <DiscoPage
      headerContent={<CheckoutPageHeader step={"summary"} />}
      classes={{ container: classes.root }}
    >
      <CheckoutSummary cart={cart} onCheckout={onCheckout} />
    </DiscoPage>
  )

  function onCheckout(params: { validCheckoutId: GlobalID; nextStep: CheckoutStep }) {
    const { validCheckoutId, nextStep } = params
    const path = ROUTE_NAMES.CHECKOUT[nextStep === "payment" ? "PAYMENT" : "PROCESS"]

    history.push({
      pathname: generatePath(path, { validCheckoutId }),
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default CheckoutSummaryPage
