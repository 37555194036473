import { CheckoutExperienceListItemQuery } from "@/checkout/components/__generated__/CheckoutExperienceListItemQuery.graphql"
import CheckoutListItemTemplate, {
  CheckoutListItemTemplateSkeleton,
} from "@/checkout/components/CheckoutListItemTemplate"
import { useLabel } from "@/core/context/LabelsContext"
import { Pricing } from "@/pricing/pricingUtils"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export type Props = {
  experienceId: GlobalID
  planId?: GlobalID | null
  onRemove?: (id: GlobalID) => void
}

function CheckoutExperienceListItem({ experienceId, planId, onRemove }: Props) {
  const experienceLabel = useLabel("experience")

  const { experience } = useLazyLoadQuery<CheckoutExperienceListItemQuery>(
    graphql`
      query CheckoutExperienceListItemQuery($id: ID!) {
        experience: node(id: $id) {
          ... on Product {
            id
            name
            cover
            startsAt
            endsAt
            includedInBenefits(hideDrafts: true) {
              edges {
                node {
                  id
                  membershipPlanId
                  pricing {
                    id
                    amountCents
                    frequency
                    kind
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: experienceId }
  )

  if (!experience) return null

  const benefits = Relay.connectionToArray(experience.includedInBenefits)
  const benefitByPlanId = ArrayUtils.mapBy(benefits, "membershipPlanId")
  const allSamePricing = benefits.every(
    (benefit) =>
      benefit.pricing.amountCents === benefits[0].pricing.amountCents &&
      benefit.pricing.frequency === benefits[0].pricing.frequency
  )

  return (
    <CheckoutListItemTemplate
      id={experienceId}
      name={experience.name || `Untitled ${experienceLabel.singular}`}
      pricing={renderPricing()}
      details={renderEntityDetailsText()}
      chipLabel={experienceLabel.singular}
      chipColor={"yellow"}
      cover={experience.cover}
      onRemove={onRemove}
    />
  )

  function renderPricing(): Pricing | string {
    if (allSamePricing) return benefits[0].pricing
    if (planId) return benefitByPlanId[planId]?.pricing
    return "--"
  }

  function renderEntityDetailsText() {
    if (!experience?.startsAt) return ""

    const startDate = new Date(experience.startsAt)
    return `Starts ${formatDateWithOptions({
      format: DATE_FORMAT.DEFAULT,
    })(startDate)} · ${ProductUtils.displayDuration(experience)}`
  }
}

export function CheckoutExperienceListItemSkeleton() {
  return <CheckoutListItemTemplateSkeleton />
}

export default Relay.withSkeleton({
  component: observer(CheckoutExperienceListItem),
  skeleton: CheckoutExperienceListItemSkeleton,
})
