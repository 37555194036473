import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  errorMessages?: string[]
  cta?: React.ReactNode
}

function CheckoutError({ errorMessages, cta }: Props) {
  const classes = useStyles()
  const errors = errorMessages?.length
    ? errorMessages
    : ["Something went wrong. Please try again."]

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <DiscoText variant={"heading-md-700"}>{"Oops!"}</DiscoText>
        {errors.map((error) => (
          <DiscoText key={error} variant={"body-md"} marginBottom={2}>
            {error}
          </DiscoText>
        ))}

        {cta}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "750px",
    gap: theme.spacing(1),
    justifyContent: "center",
    height: "100%",
    marginBottom: "40%",
    padding: theme.spacing(10, 0),
  },
}))

export default CheckoutError
