import CheckoutError from "@/checkout/error/CheckoutError"
import { CheckoutProcessMutation } from "@/checkout/process/__generated__/CheckoutProcessMutation.graphql"
import { CheckoutStep } from "@/checkout/utils/CheckoutUtils"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import ThreeDotLoaderGradient from "@assets/images/onboarding/processing/three-dot-loader-gradient.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

type Props = {
  validCheckoutId: GlobalID
  onProcessed: (params: { validCheckoutId: GlobalID; nextStep: CheckoutStep }) => void
}

function CheckoutProcess({ validCheckoutId, onProcessed }: Props) {
  const classes = useStyles()
  const history = useHistory()

  const processForm = useFormStore<CheckoutProcessMutation>(
    graphql`
      mutation CheckoutProcessMutation($input: ProcessCheckoutInput!) {
        response: processCheckout(input: $input) {
          success
          errors {
            field
            message
          }
        }
      }
    `,
    { validCheckoutId },
    { showErrorToast: false }
  )

  // Trigger form submission on page load
  useEffect(() => {
    handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If there are errors, show the error message
  if (processForm.errors.length) {
    const errorMessages = processForm.errors.map((error) => error.message)
    return (
      <CheckoutError
        errorMessages={errorMessages}
        cta={<DiscoButton onClick={history.goBack}>{"Go Back"}</DiscoButton>}
      />
    )
  }

  // Loading form
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ThreeDotLoaderGradient />
        <DiscoText variant={"heading-md"} marginTop={2}>
          {"Completing Registration..."}
        </DiscoText>
        <DiscoText variant={"body-md"} marginTop={1} color={"text.secondary"}>
          {"This could take a moment."}
        </DiscoText>
      </div>
    </div>
  )

  async function handleSubmit() {
    if (!validCheckoutId) return

    const [{ didSave, response }] = await Promise.all([
      processForm.submit({ validCheckoutId }),
      // Wait at least 2 seconds before redirecting to the success page to simulate a loading state
      new Promise((resolve) => setTimeout(resolve, 2000)),
    ])
    if (!didSave || !response) return

    onProcessed({ validCheckoutId, nextStep: "complete" })
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "750px",
    gap: theme.spacing(1),
    justifyContent: "center",
    height: "100%",
    marginBottom: "40%",
    padding: theme.spacing(10, 0),
  },
}))

export function CheckoutProcessSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ThreeDotLoaderGradient />
        <DiscoText variant={"heading-md"} marginTop={2}>
          {"Completing Registration..."}
        </DiscoText>
        <DiscoText variant={"body-md"} marginTop={1} color={"text.secondary"}>
          {"This could take a moment."}
        </DiscoText>
      </div>
    </div>
  )
}

export default observer(CheckoutProcess)
