import { CheckoutEntityListItem_PricingFragment$key } from "@/checkout/components/__generated__/CheckoutEntityListItem_PricingFragment.graphql"
import CheckoutListItemTemplate, {
  CheckoutListItemTemplateSkeleton,
} from "@/checkout/components/CheckoutListItemTemplate"
import {
  CheckoutSummary_ValidateCheckoutMutation,
  ValidateCheckoutInput,
} from "@/checkout/summary/__generated__/CheckoutSummary_ValidateCheckoutMutation.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { getPricingKindLabel } from "@/pricing/pricingUtils"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { Theme, useMediaQuery } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type Props = {
  pricingKey: CheckoutEntityListItem_PricingFragment$key
  validateForm?: FormStore<
    ValidateCheckoutInput,
    CheckoutSummary_ValidateCheckoutMutation
  >
  onRemove?: (id: GlobalID) => void
  onSelect?: (id: GlobalID) => void
  buttons?: React.ReactNode[]
}

function CheckoutEntityListItem({
  pricingKey,
  validateForm,
  onRemove,
  onSelect,
  buttons,
}: Props) {
  const experienceLabel = useLabel("experience")
  const isSmDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))

  const pricing = useFragment<CheckoutEntityListItem_PricingFragment$key>(
    graphql`
      fragment CheckoutEntityListItem_PricingFragment on Pricing {
        id
        amountCents
        frequency
        kind
        membershipPlan {
          id
          name
          cover
          membershipBenefits(hideNonPublic: true, hideDrafts: true) {
            totalCount
          }
        }
        membershipBenefit {
          id
          membershipPlan {
            name
          }
          product {
            id
            name
            cover
            startsAt
            endsAt
          }
        }
      }
    `,
    pricingKey
  )

  if (!pricing) return null

  const { membershipPlan, membershipBenefit } = pricing
  const name = membershipPlan?.name || membershipBenefit?.product?.name || ""
  const cover = membershipPlan?.cover || membershipBenefit?.product?.cover

  const errorField = () => {
    if (membershipPlan) return membershipPlan.id
    if (membershipBenefit) return membershipBenefit.id
    return pricing.id
  }
  const pricingErrors = validateForm?.errorsByField[errorField()]

  return (
    <CheckoutListItemTemplate
      id={pricing.id}
      name={name}
      pricing={pricing}
      pricingDetails={renderPricingDetailsText()}
      details={renderEntityDetailsText()}
      chipLabel={membershipPlan ? "Membership" : experienceLabel.singular}
      chipColor={membershipPlan ? "blue" : "yellow"}
      cover={cover}
      pricingErrors={pricingErrors}
      onRemove={onRemove}
      onSelect={onSelect}
      buttons={buttons}
    />
  )

  function renderEntityDetailsText() {
    if (membershipPlan)
      return `Includes access to ${pluralize(
        experienceLabel.plural,
        membershipPlan.membershipBenefits?.totalCount,
        true
      )}. Access starts immediately`
    if (!membershipBenefit?.product?.startsAt) return ""

    const startDate = new Date(membershipBenefit.product.startsAt)
    return `Starts ${formatDateWithOptions({
      format: DATE_FORMAT.DEFAULT,
    })(startDate)} · ${ProductUtils.displayDuration(membershipBenefit.product)}`
  }

  function renderPricingDetailsText() {
    if (!pricing) return ""
    if (pricing.kind === "free") return ""
    return getPricingKindLabel(pricing.kind, pricing.frequency, { short: isSmDown })
  }
}

export function CheckoutEntityListItemSkeleton() {
  return <CheckoutListItemTemplateSkeleton />
}

export default Relay.withSkeleton({
  component: observer(CheckoutEntityListItem),
  skeleton: CheckoutEntityListItemSkeleton,
})
