import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { generateReadablePricing, Pricing } from "@/pricing/pricingUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import {
  DiscoAlert,
  DiscoChip,
  DiscoFormControl,
  DiscoIcon,
  DiscoSection,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
} from "@disco-ui"
import { Theme, useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import React from "react"

export type Props = {
  id: GlobalID
  name: string
  pricing: Pricing | string
  details?: string
  pricingDetails?: string
  chipLabel?: string
  chipColor?: "blue" | "yellow"
  pricingErrors?: string[]
  cover?: string
  onRemove?: (id: GlobalID) => void
  onSelect?: (id: GlobalID) => void
  buttons?: React.ReactNode[]
}

function CheckoutListItemTemplate({
  id,
  name,
  pricing,
  details,
  pricingDetails,
  chipLabel,
  chipColor = "blue",
  pricingErrors,
  cover,
  onRemove,
  onSelect,
  buttons,
}: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const showOnHoverClasses = useShowOnHoverStyles()
  const activeOrganization = useActiveOrganization()
  const isSmDown = useMediaQuery<Theme>(() => theme.breakpoints.down("sm"))
  const isXsDown = useMediaQuery<Theme>(() => theme.breakpoints.down("xs"))

  return (
    <DiscoFormControl
      key={id}
      error={Boolean(pricingErrors)}
      errorMessages={pricingErrors}
      marginBottom={0}
      hideErrors
      className={showOnHoverClasses.hoverable}
      onClick={onSelect ? () => onSelect(id) : undefined}
    >
      <DiscoSection
        groovyDepths={"insideCard"}
        padding={1.5}
        className={classNames(classes.container, {
          [classes.selectButton]: Boolean(onSelect),
        })}
      >
        <div className={classes.content}>
          <div className={classes.lhs}>
            {!isXsDown && (
              <div className={classes.coverWrapper}>
                <CoverPhoto
                  coverPhoto={cover || defaultThumbnail}
                  customClassName={classes.cover}
                />
              </div>
            )}

            <div className={classes.details}>
              {isXsDown && (
                <div className={classes.coverWrapper}>
                  <CoverPhoto
                    coverPhoto={cover || defaultThumbnail}
                    customClassName={classes.cover}
                  />
                </div>
              )}

              <div className={classes.title}>
                <DiscoText variant={"body-md-600"}>{name}</DiscoText>
                {renderChip()}
              </div>

              {details && (
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {details}
                </DiscoText>
              )}

              {isSmDown && (
                <div className={classes.prices}>
                  <DiscoText variant={"body-sm-600"}>
                    {typeof pricing === "string"
                      ? pricing
                      : generateReadablePricing(pricing, activeOrganization?.currency)}
                  </DiscoText>
                </div>
              )}
            </div>
          </div>

          {!isSmDown && (
            <div className={classes.rhs}>
              <div className={classes.prices}>
                <DiscoText variant={"body-md-600"}>
                  {typeof pricing === "string"
                    ? pricing
                    : generateReadablePricing(
                        pricing,
                        activeOrganization?.currency,
                        isSmDown ? "short" : "full"
                      )}
                </DiscoText>

                {pricingDetails && (
                  <DiscoText
                    variant={"body-sm"}
                    color={"text.secondary"}
                    className={classes.pricingDetails}
                  >
                    {typeof pricing === "object" && pricing?.kind === "recurring" && (
                      <DiscoIcon
                        icon={"repeat-credit-card"}
                        color={theme.palette.text.secondary}
                      />
                    )}
                    {pricingDetails}
                  </DiscoText>
                )}
              </div>
            </div>
          )}
        </div>

        {pricingErrors?.map((error) => (
          <DiscoAlert
            key={error}
            severity={"error"}
            message={error}
            cta={
              <DiscoTextButton
                textVariant={"body-sm-600"}
                color={theme.palette.groovy.red[700]}
                onClick={() => onRemove?.(id)}
              >
                {"Remove"}
              </DiscoTextButton>
            }
          />
        ))}
      </DiscoSection>

      <div className={classNames(classes.buttons, showOnHoverClasses.showable)}>
        {buttons?.map((button) => (
          <React.Fragment key={button?.toString()}>{button}</React.Fragment>
        ))}
      </div>
    </DiscoFormControl>
  )

  function renderChip() {
    if (!chipLabel) return null
    return <DiscoChip label={chipLabel} color={chipColor} borderRadius={"default"} />
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    width: "100%",
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1.5),
    width: "100%",
  },
  coverWrapper: {
    width: 150,
    flexShrink: 0,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  cover: {
    borderRadius: theme.measure.borderRadius.default,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  title: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  lhs: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1.5),
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
    },
  },
  rhs: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    alignItems: "center",
  },
  pricing: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
    width: "100%",
  },
  prices: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "flex-end",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  pricingDetails: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    textAlign: "end",
    width: "max-content",
  },
  selectButton: {
    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    gap: theme.spacing(1),
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  skeleton: {
    width: "100%",
    flex: "none",
  },
}))

export function CheckoutListItemTemplateSkeleton() {
  const classes = useStyles()

  return (
    <DiscoSection groovyDepths={"insideCard"} padding={1.5} className={classes.container}>
      <div className={classes.pricing}>
        <div className={classes.lhs}>
          <div className={classes.coverWrapper}>
            <Skeleton
              variant={"rect"}
              height={75}
              width={150}
              className={classes.cover}
            />
          </div>
          <div className={classNames(classes.details, classes.skeleton)}>
            <div className={classes.title}>
              <DiscoTextSkeleton width={"100%"} />
            </div>
            <DiscoTextSkeleton width={"75%"} />
          </div>
        </div>
      </div>
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: observer(CheckoutListItemTemplate),
  skeleton: CheckoutListItemTemplateSkeleton,
})
