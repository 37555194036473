/**
 * @generated SignedSource<<48ddb42fc01d97fece2a973c0f6590a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type CheckoutSummaryQuery$variables = {
  id: string;
  pricingIds: ReadonlyArray<string>;
};
export type CheckoutSummaryQuery$data = {
  readonly organization: {
    readonly defaultMembershipPlan?: {
      readonly id: string;
      readonly pricing: {
        readonly id: string;
      } | null;
    };
    readonly membershipPlans?: {
      readonly totalCount: number;
    };
    readonly pricings?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly amountCents: number;
          readonly frequency: PricingFrequency | null;
          readonly kind: PricingKind;
          readonly membershipPlan: {
            readonly slug: string;
          } | null;
          readonly membershipBenefit: {
            readonly product: {
              readonly slug: string;
            } | null;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"CheckoutEntityListItem_PricingFragment">;
        };
      }>;
    };
  } | null;
};
export type CheckoutSummaryQuery = {
  variables: CheckoutSummaryQuery$variables;
  response: CheckoutSummaryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pricingIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "defaultMembershipPlan",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "hideDrafts",
  "value": true
},
v5 = {
  "kind": "Literal",
  "name": "hideNonPublic",
  "value": true
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v7 = {
  "alias": "membershipPlans",
  "args": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "kind": "Literal",
      "name": "type",
      "value": "membership_plan"
    }
  ],
  "concreteType": "ProductNodeConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "products(hideDrafts:true,hideNonPublic:true,type:\"membership_plan\")"
},
v8 = [
  {
    "kind": "Variable",
    "name": "pricingIds",
    "variableName": "pricingIds"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountCents",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frequency",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutSummaryQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "PricingNodeConnection",
                "kind": "LinkedField",
                "name": "pricings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PricingNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pricing",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "membershipPlan",
                            "plural": false,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipBenefit",
                            "kind": "LinkedField",
                            "name": "membershipBenefit",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": (v13/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CheckoutEntityListItem_PricingFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutSummaryQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "PricingNodeConnection",
                "kind": "LinkedField",
                "name": "pricings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PricingNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pricing",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "membershipPlan",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v2/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  (v4/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "concreteType": "MembershipBenefitNodeConnection",
                                "kind": "LinkedField",
                                "name": "membershipBenefits",
                                "plural": false,
                                "selections": (v6/*: any*/),
                                "storageKey": "membershipBenefits(hideDrafts:true,hideNonPublic:true)"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipBenefit",
                            "kind": "LinkedField",
                            "name": "membershipBenefit",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v2/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startsAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endsAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "membershipPlan",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dece359bcdec0b37cf5738b222a5fc54",
    "id": null,
    "metadata": {},
    "name": "CheckoutSummaryQuery",
    "operationKind": "query",
    "text": "query CheckoutSummaryQuery(\n  $id: ID!\n  $pricingIds: [ID!]!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      defaultMembershipPlan {\n        id\n        pricing {\n          id\n        }\n      }\n      membershipPlans: products(type: \"membership_plan\", hideDrafts: true, hideNonPublic: true) {\n        totalCount\n      }\n      pricings(pricingIds: $pricingIds) {\n        edges {\n          node {\n            id\n            amountCents\n            frequency\n            kind\n            membershipPlan {\n              slug\n              id\n            }\n            membershipBenefit {\n              product {\n                slug\n                id\n              }\n              id\n            }\n            ...CheckoutEntityListItem_PricingFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment CheckoutEntityListItem_PricingFragment on Pricing {\n  id\n  amountCents\n  frequency\n  kind\n  membershipPlan {\n    id\n    name\n    cover\n    membershipBenefits(hideNonPublic: true, hideDrafts: true) {\n      totalCount\n    }\n  }\n  membershipBenefit {\n    id\n    membershipPlan {\n      name\n      id\n    }\n    product {\n      id\n      name\n      cover\n      startsAt\n      endsAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4cd50328ce4d0e0110b1f09ee49a4194";

export default node;
