/**
 * @generated SignedSource<<5812a393614569b70b74c3638f1545a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CheckoutEntityListItem_PricingFragment$data = {
  readonly id: string;
  readonly amountCents: number;
  readonly frequency: PricingFrequency | null;
  readonly kind: PricingKind;
  readonly membershipPlan: {
    readonly id: string;
    readonly name: string;
    readonly cover: string;
    readonly membershipBenefits: {
      readonly totalCount: number;
    } | null;
  } | null;
  readonly membershipBenefit: {
    readonly id: string;
    readonly membershipPlan: {
      readonly name: string;
    };
    readonly product: {
      readonly id: string;
      readonly name: string;
      readonly cover: string;
      readonly startsAt: string | null;
      readonly endsAt: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "CheckoutEntityListItem_PricingFragment";
};
export type CheckoutEntityListItem_PricingFragment$key = {
  readonly " $data"?: CheckoutEntityListItem_PricingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutEntityListItem_PricingFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutEntityListItem_PricingFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "membershipPlan",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "hideDrafts",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "hideNonPublic",
              "value": true
            }
          ],
          "concreteType": "MembershipBenefitNodeConnection",
          "kind": "LinkedField",
          "name": "membershipBenefits",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "membershipBenefits(hideDrafts:true,hideNonPublic:true)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipBenefit",
      "kind": "LinkedField",
      "name": "membershipBenefit",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "membershipPlan",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startsAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endsAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pricing",
  "abstractKey": null
};
})();

(node as any).hash = "8fa596c57202103792e7264991be3c27";

export default node;
